import { useQuery } from "@tanstack/react-query";
import { callQaDashApi } from "../../api/endpoints/callQaDashApi";
import { useDateRange } from "../../context/DateRangeContext";
import { getFormattedFilters } from "../../utils/getFormattedFilters";
import { useMutation } from '@tanstack/react-query';

const useDateRangeParams = () => {
  const { dateRange } = useDateRange();
  const [startDate, endDate] = dateRange || [];
  return {
    start_date: startDate?.format("YYYY-MM-DD"),
    end_date: endDate?.format("YYYY-MM-DD"),
  };
};

const normalizeFilters = (filters) =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key] = Array.isArray(value) ? value : [value];
    return acc;
  }, {});

export const useGetFullCallLogsData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["full-call-logs-data", queryParams, dateParams], // Include dateParams in queryKey
    queryFn: () => callQaDashApi.getFullCallLogsData(queryParams),
    enabled: !!queryParams.start_date && !!queryParams.end_date,
    ...options,
  });
};

export const useGetBuyerTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["buyer-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getBuyerTableData(queryParams),
    enabled: !!queryParams.start_date && !!queryParams.end_date,
    ...options,
  });
};

export const useGetCampaignTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["campaign-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCampaignTableData(queryParams),
    ...options,
  });
};

export const useGetCreativeTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["creative-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCreativeTableData(queryParams),
    ...options,
  });
};

export const useGetStateTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["state-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getStateTableData(queryParams),
    ...options,
  });
};

export const useGetBillableChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["billable-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getBillableChartData(queryParams),
    ...options,
  });
};


export const useGetCallMetricsChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["call-metrics-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCallMetricsChartData(queryParams),
    ...options,
  });
};

export const useGetRateMetricsChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["rate-metrics-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getRateMetricsChartData(queryParams),
    ...options,
  });
};

export const useGetCpaChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["cpa-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCpaChartData(queryParams),
    ...options,
  });
};

export const useGetCardData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["card-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCardData(queryParams),
    ...options,
    keepPreviousData: true,
    staleTime: 30000,
  });
};

export const useGetDropdownData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["dropdown-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getDropdownData(queryParams),
    ...options,
  });
};

export const useGetUsMapData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["us-map-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getUsMapData(queryParams),
    ...options,
  });
};

export const useGetTrainingData = (filters = {}, options = {}) => {
  return useQuery({
    queryKey: ["training-data"],
    queryFn: () => callQaDashApi.getTrainingData(),
    ...options,
  });
};

export const useCheckTrainingDataQty = (quantity, options = {}) => {
  return useQuery({
    queryKey: ["check-training-data-qty", quantity],
    queryFn: () => callQaDashApi.checkTrainingDataQty(quantity),
    ...options,
  });
};

export const useSaveTrainingData = (options = {}) => {
  const mutation = useMutation({
    mutationFn: (data) => callQaDashApi.saveTrainingData(data),
    ...options,
  });

  return mutation;
};

export const useStartTraining = (options = {}) => {
  const mutation = useMutation({
    mutationFn: (data) => {
      console.log('Starting training with data:', data);
      return callQaDashApi.startTraining(data);
    },
    ...options,
  });

  return mutation;
};

export const useTestModel = (options = {}) => {
  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("testModel", data);
      return callQaDashApi.testModel(data);
    },
    ...options,
  });

  return mutation;
};

export const useDeleteTrainingData = () => {
  const deleteData = async (ids) => {
    await callQaDashApi.deleteTrainingData(ids);
  };
  return deleteData;
};

export const useGetRingbaCallsData = (params, options = {}) => {
  return useQuery({
    queryKey: ["ringba-calls-data", params],
    queryFn: () => callQaDashApi.getRingbaCallsData(params),
    ...options,
  });
};

export const useImportFromRingba = (data, options = {}) => {
  return useQuery({
    queryKey: ["import-from-ringba", data],
    queryFn: () => callQaDashApi.importFromRingba(data),
    ...options,
  });
};

export const useCheckImportJob = (jobId, options = {}) => {
  return useQuery({
    queryKey: ["check-import-job", jobId],
    queryFn: () => callQaDashApi.checkImportJob(jobId),
    ...options,
  });
};

export const useDownloadImportJob = (jobId, options = {}) => {
  return useQuery({
    queryKey: ["download-import-job", jobId],
    queryFn: () => callQaDashApi.downloadImportJob(jobId),
    ...options,
  });
};

export const useGetModelData = (params, options = {}) => {
  return useQuery({
    queryKey: ["qa-model-data", params],
    queryFn: () => callQaDashApi.getModelData(params),
    ...options,
  });
};

export const useCheckModelTrainingStatus = (modelName, options = {}) => {
  return useQuery({
    queryKey: ["check-model-training-status", modelName],
    queryFn: () => callQaDashApi.checkModelTrainingStatus(modelName),
    ...options,
  });
};

export const useSavePromptData = (data, options = {}) => {
  return useQuery({
    queryKey: ["save-prompt-data", data],
    queryFn: () => callQaDashApi.savePromptData(data),
    ...options,
  });
};

export const useGetPromptData = (params, options = {}) => {
  return useQuery({
    queryKey: ["get-prompt-data", params],
    queryFn: () => callQaDashApi.getPromptData(params),
    ...options,
  });
};

export const useGetPromptDataById = (promptId, options = {}) => {
  return useQuery({
    queryKey: ["get-prompt-data-by-id", promptId],
    queryFn: () => callQaDashApi.getPromptDataById(promptId),
    ...options,
  });
};

export const useUpdatePromptData = (data, options = {}) => {
  return useQuery({
    queryKey: ["update-prompt-data", data],
    queryFn: () => callQaDashApi.updatePromptData(data),
    ...options,
  });
};

export const useSetActivePrompt = (data, options = {}) => {
  return useQuery({
    queryKey: ["set-active-prompt", data],
    queryFn: () => callQaDashApi.setActivePrompt(data),
    ...options,
  });
};
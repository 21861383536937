import { useQuery, useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { campaignApi } from "../../api/endpoints/campaignApi";
import { useDateRange } from "../../context/DateRangeContext";

const useDateRangeParams = () => {
  const { dateRange } = useDateRange();
  const [startDate, endDate] = dateRange || [];
  return {
    start_date: startDate?.format("YYYY-MM-DD"),
    end_date: endDate?.format("YYYY-MM-DD"),
  };
};

const useUrlFilters = () => {
  const [searchParams] = useSearchParams();
  const filters = {};
  for (const [key, value] of searchParams.entries()) {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue.includes(',')) {
      filters[key] = decodedValue.split(',');
    } else {
      if (!filters[key]) {
        filters[key] = [];
      }
      filters[key].push(decodedValue);
    }
  }
  return filters;
};

export const useGetPnlsCampaigns = (params, options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };

  return useQuery({
    queryKey: ["pnls-campaigns", queryParams],
    queryFn: () => campaignApi.getPnlsCampaigns(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetTrendCampaigns = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    date: dateParams.start_date,
    ...filters,
  };

  return useQuery({
    queryKey: ["trend-campaigns", queryParams],
    queryFn: () => campaignApi.getTrendCampaigns(queryParams),
    enabled: !!dateParams.start_date,
    ...options,
  });
};

export const useGetUniqueVerticals = (options = {}) => {
  const dateParams = useDateRangeParams();
  const  isLoggedIn  = localStorage.getItem('authStatus');

  return useQuery({
    queryKey: ["verticals", dateParams],
    queryFn: () => campaignApi.getUniqueVerticals(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date && !!isLoggedIn,
    ...options,
  });
};

export const useGetUniqueTrafficPlatforms = (options = {}) => {
  const dateParams = useDateRangeParams();
  const  isLoggedIn  = localStorage.getItem('authStatus');

  return useQuery({
    queryKey: ["traffic-platforms", dateParams],
    queryFn: () => campaignApi.getUniqueTrafficPlatforms(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date && !!isLoggedIn,
    ...options,
  });
};

export const useGetUniqueTrafficPlatformSources = (options = {}) => {
  const dateParams = useDateRangeParams();
  const  isLoggedIn  = localStorage.getItem('authStatus');

  return useQuery({
    queryKey: ["traffic-platform-sources", dateParams],
    queryFn: () => campaignApi.getUniqueTrafficPlatformSources(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date && !!isLoggedIn,
    ...options,
  });
};

export const useGetUniqueAgencies = (options = {}) => {
  const dateParams = useDateRangeParams();
  const  isLoggedIn  = localStorage.getItem('authStatus');

  return useQuery({
    queryKey: ["agencies", dateParams],
    queryFn: () => campaignApi.getUniqueAgencies(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date && !!isLoggedIn,
    ...options,
  });
};

export const useGetUniqueMediaBuyers = (options = {}) => {
  const dateParams = useDateRangeParams();
  const  isLoggedIn  = localStorage.getItem('authStatus');

  return useQuery({
    queryKey: ["media-buyers", dateParams],
    queryFn: () => campaignApi.getUniqueMediaBuyers(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date && !!isLoggedIn,
    ...options,
  });
};

export const useGetMetrics = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
  };

  return useQuery({
    queryKey: ["dash-metrics", queryParams],
    queryFn: () => campaignApi.getMetrics(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetHourlyTrends = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
  };

  return useQuery({
    queryKey: ["dash-hourly-trends", queryParams],
    queryFn: () => campaignApi.getHourlyTrends(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetMediaBuyers = (options = {}) => {
  return useQuery({
    queryKey: ["media-buyers"],
    queryFn: () => campaignApi.getMediaBuyers(),
    ...options,
  });
};

export const useGetVerticals = (options = {}) => {
  return useQuery({
    queryKey: ["verticals"],
    queryFn: () => campaignApi.getVerticals(),
    ...options,
  });
};

export const useUpdateCampaignBudget = (options = {}) => {
  return useMutation({
    mutationFn: (data) => campaignApi.updateCampaignBudget(data),
    ...options,
  });
};

export const useUpdateCampaignStatus = (options = {}) => {
  return useMutation({
    mutationFn: (data) => campaignApi.updateCampaignStatus(data),
    ...options,
  });
};


export const useUploadCampaignCsv = (options = {}) => {
  return useMutation({
    mutationFn: (data) => campaignApi.uploadCampaignCsv(data),
    ...options,
  });
};

export const useGetPnlAdsByCreative = (params = {},options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };

  console.log("Sent A Request to Get PNLADSBY");

  return useQuery({
    queryKey: ["pnl-ads-by-creative", queryParams],
    queryFn: async () => {
      const data = await campaignApi.getCreativePnl(queryParams);
      console.log("Received PNLADSBY data:", data);
      return data;
    },
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

// export const useUpdateFilterPnlAdsByCreative = (params = {},options = {}) => {
//   const dateParams = useDateRangeParams();
//   const filters = useUrlFilters();

//   let queryParams = {
//     ...dateParams,
//     ...filters,
//     ...params
//   };

//   const mutation =  useMutation({
//     mutationFn:(data) => campaignApi.updateFilterPnlAdsByCreative(queryParams,data),
//     ...options,
//   })

//   const updatePnl = () => {
//     const data = {};
//     mutation.mutate(data, {
//       onSuccess: () => {
//         console.log("Update successful mutate");
//       },
//       onError: (err) => {
//         console.error("Update failed: mutate", err);
//       },
//     });
//   };

//   useEffect(() => {
//     if (dateParams.start_date && dateParams.end_date) {
//       updatePnl();
//     }
//   }, [JSON.stringify(params)]);

//   useEffect(() => {
//     if (dateParams.start_date && dateParams.end_date) {
//        queryParams['page'] = 0;
//       updatePnl();
//     }
//   }, [dateParams.start_date, dateParams.end_date, JSON.stringify(filters)]); 

//   return mutation;
// }

export const useGetFilterPnlAdsByLander = (params,options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();
  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };
  return useQuery({
    queryKey: ["pnl-landers", queryParams],
    queryFn: () => campaignApi.getFilterPnlAdsBylander(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetPnlAds = (params,options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };

  return useQuery({
    queryKey: ["pnl-ads", queryParams],
    queryFn: () => campaignApi.getPnlAds(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useUpdateCampaignsManually = (options = {}) => {
  return useMutation({
    mutationFn: (data) => campaignApi.updateCampaignsManually(data),
    ...options,
  });
};

export const useGetCreativeStateWiseData = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useMutation({
    mutationFn: (data) => {
      const payload = { ...data, ...dateParams, ...filters }
      return campaignApi.getCreativeStateWiseData(payload)
    },
  })
}

export const useGetCreativeStateData = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useMutation({
    mutationFn: (data) => {
      const payload = { ...data, ...dateParams, ...filters }
      return campaignApi.getCreativeStateData(payload)
    },
  })
}

export const useUpdateCampaignsConfig = (options = {}) => {
  return useMutation({
    mutationFn: (data) => campaignApi.updateCampaignsConfig(data),
    ...options,
  });
};

export const useGetCreativeRankings = (creativeId, options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useQuery({
    queryKey: ['creative-detail', { creativeId, dateParams, filters }],
    queryFn: () => {
      const payload = {
        creative_id: creativeId,
        ...dateParams,
        ...filters
      };
      return campaignApi.getCreativeRankings(payload);
    },
    enabled: !!creativeId,
    ...options,
  });
};

export const useGetCreativeComparison = (creativeIds, options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useQuery({
    queryKey: ['creative-comparison', { creativeIds, dateParams, filters }],
    queryFn: () => {
      const payload = {
        creative_ids: creativeIds,
        ...dateParams,
        ...filters
      };
      return campaignApi.getCreativeRankingsComparison(payload);
    },
    enabled: !!creativeIds && creativeIds.length > 0,
    ...options,
  });
};

export const useGetLanderComparison = (landerIds, options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useQuery({
    queryKey: ['creative-comparison', { landerIds, dateParams, filters }],
    queryFn: () => {
      const payload = {
        landers: landerIds,
        ...dateParams,
        ...filters
      };
      return campaignApi.getLanderRankingsComparison(payload);
    },
    enabled: !!landerIds && landerIds.length > 0,
    ...options,
  });
};

export const useGetCreativeSauceView = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useQuery({
    queryKey: ['creative-sauce-view', { dateParams, filters }],
    queryFn: () => {
      const payload = {
        ...dateParams,
        ...filters
      };
      return campaignApi.getCreativesSauceView(payload);
    },
    ...options,
  });
};

export const useGetLanderSauceView = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useQuery({
    queryKey: ['lander-sauce-view', { dateParams, filters }],
    queryFn: () => {
      const payload = {
        ...dateParams,
        ...filters
      };
      return campaignApi.getLanderSauceView(payload);
    },
    enabled:  !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetLanderSauceViewCharts = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useQuery({
    queryKey: ['lander-sauce-view-charts', { dateParams, filters }],
    queryFn: () => {
      const payload = {
        ...dateParams,
        ...filters
      };
      return campaignApi.getLanderSauceViewCharts(payload);
    },
    enabled:  !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetLanderDetails = (lander, options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  return useQuery({
    queryKey: ['lander-detail', { lander, dateParams, filters }],
    queryFn: () => {
      const payload = {
        lander,
        ...dateParams,
        ...filters
      };
      return campaignApi.getLanderDetails(payload);
    },
    enabled: !!lander,
    ...options,
  });
};

export const useGetAssociatedAds = (params,options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };

  return useQuery({
    queryKey: ["creative-pnl-associated-ads", queryParams],
    queryFn: () => campaignApi.getAssociatedAds(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetPnlAccount = (params,options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };

  return useQuery({
    queryKey: ["pnl-account", queryParams],
    queryFn: () => campaignApi.getPnlAccount(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetLanderCreativeCombo = (params,options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };

  return useQuery({
    queryKey: ["lander-creative-combo", queryParams],
    queryFn: () => campaignApi.getLanderCreativeCombo(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetCfCampaigns = (params,options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };

  return useQuery({
    queryKey: ["cf-campaigns", queryParams],
    queryFn: () => campaignApi.getCfCampaigns(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetCfLander = (params,options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
    ...params
  };

  return useQuery({
    queryKey: ["cf-lander", queryParams],
    queryFn: () => campaignApi.getCfLander(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

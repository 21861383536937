import { lazy } from "react";

const LoginPage = lazy(() => import("../pages/Login"));
const RegisterPage = lazy(() => import("../pages/Signup"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Pnl = lazy(() => import("../pages/Pnl"));
const Trends = lazy(() => import("../pages/Trends"));
const CreateUser = lazy(() => import("../components/users/CreateUser"));
const UserDetail = lazy(() => import("../components/users/UserDetails"));
const UpdateUser = lazy(() => import("../components/users/UpdateUser"));
const User = lazy(() => import("../pages/Users"));
const AdAccountList = lazy(() => import("../pages/AdAccountList"));
const CPAList = lazy(() => import("../pages/CPA"));
const Profile = lazy(() => import("../pages/Profile"));
const Status = lazy(() => import("../pages/Status"));
const CallQaAiDash = lazy(() => import("../components/cpa/CallQaAiDash"));
const CallQaAiPlayground = lazy(() => import("../components/cpa/playground/CallQaAiPlayground"));
const CallQaAiStatistics = lazy(() => import("../components/cpa/playground/CallQaAiStatistics"));
const CallQaAiTraining = lazy(() => import("../components/cpa/training/CallQaAiTraining"));
const CallQaAiRingbaCalls = lazy(() => import("../components/cpa/ringba/CallQaAiRingbaCalls"));
const PnlAds = lazy(() => import("../pages/PnlAds"));
const PnlAdsByCreative = lazy(() => import("../pages/PnlAdsByCreative"));
const PnlLanders = lazy(() => import("../pages/PnlLanders"));
const PnlAdsByCreativeLayout = lazy(() => import("../pages/PnlAdsByCreativeLayout"));
const PnlCreativeDetails = lazy(() => import("../components/pnlCreatives/PnlCreativeDetails"));
const CreativeComparison = lazy(() => import("../components/pnlCreatives/CreativesComparison"));
const PnlCreativeAds = lazy(() => import("../components/pnlCreatives/PnlCreativeAds"));
const PnlCreative = lazy(() => import("../components/pnlCreatives/PnlCreative"));
const StateView = lazy(() => import("../components/pnlCreatives/StateView"));
const SecretSauceView = lazy(() => import("../pages/SauceView"));
const PnlLayout = lazy(() => import("../pages/PnlLayout"));

export const publicRoutes = [
  { path: "/", component: LoginPage },
  { path: "/signup", component: RegisterPage },
  { path: "/forgot-password", component: ForgotPassword },
];

export const privateRoutes = [
  { path: "/dashboard", component: Dashboard },
  // { path: "/pnl", component: Pnl },
  { path: "/pnl", component: PnlLayout },
  { path: "/trends", component: Trends },
  { path: "/users", component: User },
  { path: "/create-user", component: CreateUser },
  { path: "/update-user", component: UpdateUser },
  { path: "/user-detail", component: UserDetail },
  { path: "/ad-accounts", component: AdAccountList },
  { path: "/cpa", component: CPAList },
  { path: "/qa-ai/dashboard", component: CallQaAiDash },
  { path: "/qa-ai/playground", component: CallQaAiPlayground },
  { path: "/qa-ai/training", component: CallQaAiTraining },
  { path: "/qa-ai/ringba-calls", component: CallQaAiRingbaCalls },
  { path: "/qa-ai/statistics", component: CallQaAiStatistics },
  { path: "/profile", component: Profile },
  // { path: "/pnl-ads", component: PnlAds },
  // { path: "/pnl-creative", component: PnlAdsByCreative },
  { path: "/status", component: Status },
  { path: "/pnl-landers", component: PnlLanders },
  { path: "/pnl-creative", component:PnlAdsByCreativeLayout,
    children: [
      { path: "", component: PnlCreative },
      { path: "state-view/:creatives", component: StateView},
      { path: "state-view", component: StateView },
      { path: "ads", component: PnlCreativeAds },
      { path: "secret-sauce", component: SecretSauceView },
      { path: "creative-details/:id", component: PnlCreativeDetails },
      { path: "creative-comparison/:ids", component: CreativeComparison }
    ]
  }
];
